import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import FormMain from "./FormMain";
import FormBeside from "./FormBeside";
import Report from "./Report";
import "./App.css";
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Switch>
        <Route path="/main" exact strict component={FormMain} />
        <Route path="/beside" exact strict component={FormBeside} />
      </Switch>
    );
  }
}

export default App;
