import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
const StyledTextField = styled(TextField)`
  label {
    color: #000000;
    opacity: 1;
  }
  label.focused {
    color: #000000;
    opacity: 1;
  }
  .MuiInputBase-input {
    color: #000000;
  }
  .MuiOutlinedInput-root {
    fieldset {
      border-color: rgba(0, 0, 0, 0.23) !important;
      opacity: 0.6;
      color: #000000;
    }
    &:hover fieldset {
      border-color: rgba(0, 0, 0, 0.23) !important;
      opacity: 1;
    }
    &.Mui-focused fieldset {
      border-color: rgba(0, 0, 0, 23) !important;
      color: #000000;
      opacity: 1;
    }
  }
  .MuiFormLabel-root {
    &.Mui-focused {
      color: #000000;
      opacity: 1;
    }
  }
`;

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = { data: [], ready: false, logged: false, loggedError: false };
  }

  async handleSubmit(e) {
    e.preventDefault();
    var that = this;
    fetch("https://demo.outsafe.ch/admin/api/cockpit/authUser", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Cockpit-Token": "c9127396114d26866f8fd5586c2878"
      },
      body: JSON.stringify({
        user: document.getElementById("username").value,
        password: document.getElementById("password").value
      })
    })
      .then(user => user.json())
      .then(user => {
        if (user.error) {
          this.setState({
            loggedError: true
          });
        } else {
          this.setState({
            logged: true
          });
        }
      });
  }

  async componentDidMount() {
    let getEntries = await fetch(
      "https://demo.outsafe.ch/admin/api/forms/export/outsafe",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Cockpit-Token": "c9127396114d26866f8fd5586c2878"
        },
        body: JSON.stringify({})
      }
    );

    const entries = await getEntries.json();
    this.setState({ data: entries, ready: true });
  }

  render() {
    return (
      <div id="reportContainer">
        {this.state.logged ? (
          <div>
            <h1>Report</h1>
            <br />
            <br />
            <table>
              <tbody>
                <tr>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Table</th>
                  <th>Names</th>
                  <th>Lastnames</th>
                  <th>Phones</th>
                </tr>
                {this.state.ready
                  ? this.state.data.map((item, index) => {
                      var date = [];
                      var time = [];
                      var table = [];
                      var names = [];
                      var lastnames = [];
                      var phones = [];

                      return (
                        <tr>
                          {Object.entries(item.data).map(function(
                            [key, value],
                            index2
                          ) {
                            if (key.indexOf("Date") !== -1) {
                              date.push(value);
                            }
                            if (key.indexOf("Time") !== -1) {
                              time.push(value);
                            }
                            if (key.indexOf("Table") !== -1) {
                              table.push(value);
                            }
                            if (key.indexOf("Name") !== -1) {
                              names.push(value);
                            }
                            if (key.indexOf("Lastname") !== -1) {
                              lastnames.push(value);
                            }
                            if (key.indexOf("Phone") !== -1) {
                              phones.push(value);
                            }
                          })}
                          <td>
                            {date.map((value, index) => {
                              return (
                                <span>
                                  {value}
                                  <br />
                                </span>
                              );
                            })}
                          </td>
                          <td>
                            {time.map((value, index) => {
                              return (
                                <span>
                                  {value}
                                  <br />
                                </span>
                              );
                            })}
                          </td>
                          <td>
                            {table.map((value, index) => {
                              return (
                                <span>
                                  {value}
                                  <br />
                                </span>
                              );
                            })}
                          </td>
                          <td>
                            {names.map((value, index) => {
                              return (
                                <span>
                                  {value}
                                  <br />
                                </span>
                              );
                            })}
                          </td>
                          <td>
                            {lastnames.map((value, index) => {
                              return (
                                <span>
                                  {value}
                                  <br />
                                </span>
                              );
                            })}
                          </td>
                          <td>
                            {phones.map((value, index) => {
                              return (
                                <span>
                                  {value}
                                  <br />
                                </span>
                              );
                            })}
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
        ) : (
          <form
            className="login"
            onSubmit={e => {
              this.handleSubmit(e);
            }}
          >
            <h1>Outsafe</h1>
            <br />
            {this.state.loggedError ? (
              <div className="loggedError">Wrong username or password</div>
            ) : null}
            hint: username: <strong>demo</strong>, password:
            <strong>demo</strong>
            <StyledTextField
              style={{ width: "100%", marginBottom: "16px" }}
              required={true}
              id="username"
              name="username"
              label="Username"
              margin="normal"
              variant="outlined"
            />
            <StyledTextField
              style={{ width: "100%", marginBottom: "16px" }}
              required={true}
              type="password"
              id="password"
              name="password"
              label="Password"
              margin="normal"
              variant="outlined"
            />
            <br />
            <br />
            <Button id="send" type="submit" variant="contained">
              LOGIN
            </Button>
          </form>
        )}
      </div>
    );
  }
}

export default Report;
